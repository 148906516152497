import { HandbookDataFilter } from '@emrm/handbooks/types';
import { TemplateRef } from '@angular/core';

export interface TableCell<T> {
	dataRaw: T | null;
	data: {
		id: string;
		columnId: string;
		value: TableCellValue;
		cellType?: TableCellType;
		placeholder?: string;

		/** Конфигурация ячеек типа Select */
		selectConfig?: TableCellSelectConfig;

		/** Конфигурация справочника (актуально только для ячеек типа Handbook) */
		handbookConfig?: TableCellHandbookConfig;

		/** Формат для ячеек типа Date (по умолчанию 'dd.MM.yyyy') */
		dateFormat?: string;

		/** Определяет положение контента внутри ячейки (слева, по центру, справа) */
		position?: TableCellPosition;

		/** Флаг определяющий возможность редактирования данных в ячейке */
		canEdit?: boolean;

		/** Флаг определяющий возможность выделения ячейки (подсветить границы синим при нажатии) */
		canSelect?: boolean;

		/** Флаг для назначения особого стиля для неактивных ячеек */
		disabled?: boolean;

		/** Флаг показывающий, что ячейку редактирует другой пользователь
		 * (актуально для таблиц с возможностью одновременного редактирования через WS) */
		disableByRemote?: boolean;

		/** Конфигурация для отображения данных о том кто редактирует ячейку
		 * (актуально для таблиц с возможностью одновременного редактирования через WS) */
		disableByRemoteConfig?: TableCellDisabledByRemoteConfig;
	};
}

export type TableCellValue =
	| TableNumberCell
	| TableStringCell
	| TableDateCell
	| TableHandbookCell
	| TableUserCell;

export type TableNumberCell = number;
export type TableStringCell = string;
export type TableDateCell = string | Date;
export type TableHandbookCell = {
	id: string;
	value: string;
};

// TODO remove
export type TableUserCell = {
	id: number;
	value: string;
};

export type TableCellDisabledByRemoteConfig = {
	userId: number;
	fullName: string;
	avatar: string;
};

export type TableCellHandbookConfig = {
	name: string;
	displayField: string;
	filter?: HandbookDataFilter;
};

export type TableCellSelectConfig = {
	options: unknown[];
	multiselect: boolean;
	compareField: string;
	displayField?: string;
	prefixTemplateRef?: TemplateRef<unknown>;
};

// TODO remove user
export const TableCellType = {
	Number: 'number',
	String: 'string',
	Date: 'date',
	Handbook: 'handbook',
	Files: 'files',
	User: 'user',
	Select: 'select',
} as const;

export type TableCellType = (typeof TableCellType)[keyof typeof TableCellType];

export const TableCellPosition = {
	Left: 'left',
	Center: 'center',
	Right: 'right',
} as const;

export type TableCellPosition =
	(typeof TableCellPosition)[keyof typeof TableCellPosition];
